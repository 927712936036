/*!
Data Created On: Sat, May 17, 2023 [8:47 PM]
=========================================================
*  - v1.0.0
=========================================================
* Coded by:  Abraham Mitiku - abrilojson@gmail.com

=========================================================

*
*/
// -----------------------------------------------------------------
/**
 *  Importing View Components [Pages] , Layouts && Routes
 */

import React, { Suspense, lazy } from "react";

/**
 *  spinners or backdrop
 */
import MainBackdrop from "../components/layouts/spinners/Backdrop";

/**
 * Public Views
 * !Public view pages should be imported here and add with public view categories
 */

//-----------------

const Home = lazy(() => import("../components/views/public/home/Home"));
const About = lazy(() => import("../components/views/public/about/About"));
const Gallery = lazy(() =>
  import("../components/views/public/gallery/Gallery")
);
const Courses = lazy(() =>
  import("../components/views/public/courses/Courses")
);

/**
 * 


 */

//
const Article = lazy(() =>
  import("../components/views/public/articles/Article")
);
const ArticleDetail = lazy(() =>
  import("../components/views/public/articles/Preview")
);

const Contact = lazy(() =>
  import("../components/views/public/contact/Contact")
);

const NotFound = lazy(() => import("../components/views/404/NotFound"));

//

/**
 *  Layouts [Private, Public]
 *  Contains - [Outlet, Navigations ... etc]
 */

const PublicLayout = lazy(() => import("../components/layouts/PublicLayout"));

const routes = [
  /**
   *  Public Views
   *  Directories:
   *  Views -> home,
   */
  {
    path: "/",
    element: (
      <Suspense fallback={<MainBackdrop />}>
        <PublicLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Home />
          </Suspense>
        ),
      },
      //
      {
        path: "/courses",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            {" "}
            <Courses />
          </Suspense>
        ),
      },
      //
      {
        path: "/gallery",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Gallery />
          </Suspense>
        ),
      },
      {
        path: "/articles",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Article />
          </Suspense>
        ),
      },
      {
        path: "/articles/:id",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <ArticleDetail />
          </Suspense>
        ),
      },
      {
        path: "/loading-animation",
        element: <MainBackdrop />,
      },

      /**
       *
       */

      {
        path: "/about",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <About />
          </Suspense>
        ),
      },
      // supports
      {
        path: "/contact",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Contact />
          </Suspense>
        ),
      },
    ],
  },

  /**
   *
   */
  {
    path: "*",
    element: <NotFound />,
  },
];
export default routes;
