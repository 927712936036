import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import getDesignTokens from "../../../constants/theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//import { getLocalStorage } from "../../../utils/Storage";
import { useLottie } from "lottie-react";
import siteLoadingAnimation from "../../../constants/animation.json";

const MainBackdrop = () => {
  const [mode, setMode] = useState("light");
  const [color, setColor] = useState("secondary.light");
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  /**
   *  Animation
   */
  const options = {
    animationData: siteLoadingAnimation,
    loop: true,
  };
  const style = {
    height: 300,
  };

  const { View } = useLottie(options);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "block",
            backgroundColor: "#fff",
          }}
          open={true}>
          <Box
            sx={{ p: { md: 2, sm: 0 }, height: `100%`, position: "relative" }}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  ml: 1,
                  cursor: "pointer",
                  mt: 2,
                }}></Box>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "absolute",
                top: { xs: `32%`, sm: `30%`, md: `30%`, lg: `30%` },
                left: { xs: `11%`, sm: `31%`, md: `35%`, lg: `37%` },
              }}>
              {View}
            </Box>
          </Box>
        </Backdrop>
      </ThemeProvider>
    </>
  );
};
MainBackdrop.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
  appLayer: PropTypes.bool,
};

export default MainBackdrop;
