// site theme modes [light, dark];
// Edit/Change your site themes and coloring from this file:
import { createTheme } from "@mui/material/styles";
import { green, teal, amber, cyan } from "@mui/material/colors";

const defaultTheme = createTheme();
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#606060`,
          },
          secondary: {
            main: amber[700],
            dark: amber[800],
            light: amber[600],
          },

          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `#f4f4f4`,
          },
          text: {
            primary: "#0c0627",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#fff",
            paper: "#ffffff",
          },
          custom: defaultTheme.palette.augmentColor({
            color: {
              main: `#0c052e`,
              dark: `#080320`,
              light: `#110740`,
            },
            name: "custom",
          }),
        }
      : {
          // DARK MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#707070`,
          },
          secondary: {
            main: `#0099CC`,
            dark: `#006b8e`,
            light: `#0099CC`,
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },
          custom: defaultTheme.palette.augmentColor({
            color: {
              main: amber[500],
              dark: amber[600],
              light: amber[400],
            },
            name: "custom",
          }),
          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `rgba(0,0,0, 0.3)`,
          },
          text: {
            primary: "#1d1e1f",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#ffffff",
            paper: "#ffffff",
          },
        }),
  },
});

export default getDesignTokens;
