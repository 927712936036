import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  let elements = useRoutes(routes);
  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>{elements}</HelmetProvider>
    </>
  );
};

export default App;
