import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  firstName: undefined,
  lastName: undefined,
  displayName: undefined,
  photo: undefined,
  email: undefined,
  joined: undefined,
};

export const AuthUserContext = createContext(INITIAL_STATE);

const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ACCOUNT":
      //  console.log(action.payload);
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        displayName: action.payload.displayName,
        email: action.payload.email,
        joined: action.payload.joined,
      };
    case "NAME":
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        displayName: action.payload.displayName,
      };
    case "PHOTO":
      return {
        ...state,
        photo: action.payload.photo,
      };
    case "EMAIL":
      return {
        ...state,
        email: action.payload.email,
      };
    case "RESET_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const AuthUserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE);

  return (
    <AuthUserContext.Provider
      value={{
        firstName: state.firstName,
        lastName: state.lastName,
        displayName: state.displayName,
        photo: state.photo,
        email: state.email,
        joined: state.joined,
        dispatch,
      }}>
      {children}
    </AuthUserContext.Provider>
  );
};
